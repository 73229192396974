import CTA from "../CTA/CTA";
import Form from "../Form/Form";
import Hero from "../Hero/Hero";
import "./Home.scss";
function Home() {
  return (
    <>
    <Form/>
    <Hero/>
    <CTA/>
    </>
  );
}

export default Home;
